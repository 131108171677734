<template>
  <div class="login-container">
    <h3>{{ appTitle }}</h3>
    <h4>SIXTY-SIX DISTRIBUTION MANAGEMENT SYSTEM</h4>
    <Form
      ref="loginForm"
      class="login-form"
      :model="form"
      :rules="rules"
      @keydown.enter.native="onSubmit"
    >
      <FormItem prop="userName">
        <Input clearable placeholder="请输入用户名" v-model="form.userName">
          <template #prepend>
            <Icon :size="20" type="ios-person" />
          </template>
        </Input>
      </FormItem>
      <FormItem prop="userPwd">
        <Input
          clearable
          placeholder="请输入密码"
          type="password"
          v-model="form.userPwd"
        >
          <template #prepend>
            <Icon type="md-lock" :size="20" />
          </template>
        </Input>
      </FormItem>
      <FormItem prop="checkCode">
        <Input clearable placeholder="请输入验证码" v-model="form.checkCode">
          <template #prepend>
            <Icon type="md-lock" :size="20" />
          </template>
          <template #append>
            <img
              :src="checkCodeUrl"
              alt="看不清？点击更换"
              onclick="this.src=this.src+'?'"
            />
          </template>
        </Input>
      </FormItem>
      <FormItem style="text-align: right; margin-bottom: 10px">
        <Checkbox v-model="form.keepAlive">记住密码</Checkbox>
      </FormItem>
      <FormItem>
        <Button
          :loading="loading"
          :diabled="loading"
          long
          type="primary"
          @click="onSubmit"
          >登录</Button
        >
      </FormItem>
    </Form>
    <div class="login-tip">
      <img src="~@/assets/images/left_icon.png" alt="" srcset="" />
      <span>为了更好的使用本系统建议使用谷歌浏览器</span>
      <img src="~@/assets/images/left_icon.png" alt="" srcset="" />
    </div>
    <div>
      <a href="https://beian.miit.gov.cn/">蜀ICP备2023027500号</a>
    </div>
    <!-- <img
      width="104px"
      height="29px"
      src="~@/assets/images/logo_cr.png"
      alt=""
    /> -->
    <Modal
      v-model="showChangePwd"
      :mask-closable="false"
      :closable="false"
      title="修改密码"
      :width="520"
    >
      <Form
        ref="changePwd"
        :label-width="100"
        label-position="right"
        :model="pwdForm"
        :rules="pwdRules"
      >
        <FormItem label="原密码" prop="oldPass">
          <Input placeholder="请输入现在使用的密码" v-model="pwdForm.oldPass" />
        </FormItem>
        <FormItem label="新密码" prop="newPass">
          <Input
            placeholder="请输入新密码,仅支持6-20位英文、数字或符号组合"
            v-model="pwdForm.newPass"
          />
        </FormItem>
        <FormItem label="确认新密码" prop="rePass">
          <Input placeholder="请再次输入新密码" v-model="pwdForm.rePass" />
        </FormItem>
      </Form>
      <template #footer>
        <Button type="text" @click="onCancelChangePwd">取消</Button>
        <Button
          :loading="changePwdLoading"
          :disbaled="changePwdLoading"
          @click="onChangePwd"
          type="primary"
          >保存</Button
        >
      </template>
    </Modal>
  </div>
</template>
<script>
import { HOST } from "@/constant";
import { mapActions } from "vuex";
import CryptoJS from "crypto-js";
import api from "@/api";

export default {
  name: "Login",
  data() {
    this.appTitle = process.env.VUE_APP_TITLE;
    this.rules = {
      userName: [{ required: true, message: "账号不能为空", trigger: "blur" }],
      userPwd: [{ required: true, message: "密码不能为空", trigger: "blur" }],
      checkCode: [
        { required: true, message: "验证码不能为空", trigger: "blur" },
      ],
    };
    this.pwdRules = {
      oldPass: [{ required: true, message: "请输入原密码", trigger: "blur" }],
      newPass: [
        { required: true, message: "请输入新密码", trigger: "blur" },
        {
          validator: (_, value, callback) => {
            if (value !== this.pwdForm.newPass) {
              callback(new Error("两次输入的密码不一致"));
            } else {
              callback();
            }
          },
          trigger: "blur",
        },
      ],
      rePass: [
        { required: true, message: "请再次输入新密码", trigger: "blur" },
        {
          validator: (_, value, callback) => {
            if (value === this.pwdForm.oldPass)
              callback(new Error("新密码不能与旧密码一样"));
            if (value.length < 6 || value.length > 20)
              callback(new Error("密码长度为6到20位"));
            if (value.indexOf(" ") > -1)
              callback(new Error("密码中不能含有空格符号"));
            if (
              !/[^\uFF00-\uFFFF]/.test(value) ||
              !/((?=.*\d)(?=.*\D)|(?=.*[a-zA-Z])(?=.*[^a-zA-Z]))(?!^.*[\u4E00-\u9FA5].*$)^\S{6,20}$/.test(
                value
              )
            ) {
              callback(
                new Error(
                  "密码须为6到20位英文字母、数字或符号的组合，并且至少包含其中2种"
                )
              );
            } else {
              callback();
            }
          },
          trigger: "blur",
        },
      ],
    };
    return {
      loading: false,
      showChangePwd: false,
      changePwdLoading: false,
      checkCodeUrl: `${HOST.ADMIN_API_URL}/System/GetCaptcha`,
      form: {
        userName: "",
        userPwd: "",
        checkCode: "",
        keepAlive: false,
      },
      pwdForm: {
        oldPass: "",
        newPass: "",
        rePass: "",
      },
    };
  },
  methods: {
    ...mapActions(["onLogin"]),
    refreshPage() {
      this.loading = false;
      this.$refs.loginForm.resetFields();
      this.checkCodeUrl = `${this.checkCodeUrl}?`;
    },
    onSubmit() {
      this.loading = true;
      this.$refs.loginForm.validate(async (valid) => {
        if (valid) {
          try {
            const regix =
              /((?=.*\d)(?=.*\D)|(?=.*[a-zA-Z])(?=.*[^a-zA-Z]))(?!^.*[\u4E00-\u9FA5].*$)^\S{6,20}$/;
            const res = await this.onLogin({
              ...this.form,
              userPwd: CryptoJS.MD5(this.form.userPwd).toString(),
            });
            // 密码强度判断
            if (!regix.test(this.form.userPwd)) {
              return this.$Modal.confirm({
                title: "安全提示",
                content: "为了保障您的账号安全，请务必修改登录密码",
                onOk: () => {
                  this.$refs.changePwd.resetFields();
                  this.showChangePwd = true;
                },
                onCancel: () => {
                  this.refreshPage();
                },
              });
            }

            if (res.Code === "001") {
              return this.$Modal.confirm({
                title: "安全提示",
                content: "为了你的账户安全，请修改一次登录密码",
                onOk: () => {
                  this.$refs.changePwd.resetFields();
                  this.showChangePwd = true;
                },
                onCancel: () => {
                  this.refreshPage();
                },
              });
            }

            this.$router.push({ name: "Home" });
          } catch (error) {
            this.refreshPage();
          }
        } else {
          this.$Message.error("请输入正确的信息");
          this.refreshPage();
        }
      });
    },
    onCancelChangePwd() {
      this.showChangePwd = false;
      this.changePwdLoading = false;
      this.refreshPage();
    },
    onChangePwd() {
      this.$refs.changePwd.validate(async (valid) => {
        if (valid) {
          this.changePwdLoading = true;
          try {
            await api.EditPassWord({
              oldp: this.pwdForm.oldPass,
              newp: this.pwdForm.newPass,
            });
            this.changePwdLoading = false;
            this.$Message.success("修改成功");
            this.showChangePwd = false;
            this.refreshPage();
          } catch (error) {
            this.refreshPage();
            this.changePwdLoading = false;
          }
        }
      });
    },
  },
};
</script>

<style lang="less">
.login-container {
  width: 100vw;
  height: 100vh;
  background: url("~@/assets/images/login-bg.png");
  background-size: cover;
  background-position: center;
  position: realtive;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  h3 {
    font-size: 44px;
    font-weight: 300;
    color: #fff;
    text-align: center;
    line-height: 1;
  }
  h4 {
    font-size: 14px;
    font-weight: 300;
    color: #fff;
    line-height: 28px;
    text-align: center;
  }
  .login-form {
    background: #fff;
    margin: 50px auto 68px auto;
    padding: 30px;
    border-radius: 4px;
    .ivu-input-group-append {
      padding: 0;
      img {
        display: inherit;
        width: 70px;
        height: 24px;
      }
    }
    .ivu-btn {
      height: 40px;
      background: linear-gradient(
        38deg,
        rgba(45, 140, 240, 1) 0%,
        rgba(12, 101, 194, 1) 100%
      );
      box-shadow: 0px 4px 10px 0px rgba(13, 100, 194, 0.2);
      border-radius: 4px;
      font-size: 16px;
    }
  }
  .login-tip {
    font-size: 14px;
    color: #fff;
    margin-bottom: 30px;
    position: relative;
    span {
      margin: 0 20px;
    }
  }
}
</style>
